import { ApiClient as Client, handleLogout } from "./ApiService";
import { toast } from "react-toastify";
import { emitter } from "../../App";

export const getRegionEmployees = async (regionId) => {
  try {
    const response = await Client.get_Employee_lookup_For_Region(regionId);
    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      toast.error('Something went wrong');
    }
    return false;
  }
};

export const postEmployee = async (employee) => {
  try {
    const response = await Client.employeePOST(employee);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getUnitEmployees = async (id) => {
  try {
    const response = await Client.get_Employee_For_Selected_Unit(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getUnitEmployeesLookup = async (id) => {
  try {
    const response = await Client.get_Employee_lookup_For_Selected_Unit(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getSupervisorEmployees = async (id) => {
  try {
    const response = await Client.get_Employee_For_Selected_Supervisor(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const syncWithAD = async (body) => {
  try {
    const response = await Client.forceSyncToAzureAD(body);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const assignEmployeeToMailbox = async (id) => {
  try {
    const response = await Client.assign_Employee_To_Mailbox({ id });

    toast.success("Request to create an employee mailbox has been sent!");
    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const unassignEmployeeToMailbox = async (id) => {
  try {
    const response = await Client.unassign_Employee_from_Mailbox({ id });

    toast.success("Request to remove employee's mailbox has been sent!");
    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const promoteToRestaurantAdmin = async (body) => {
  try {
    const response = await Client.promote(body);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const postLockEmployee = async (body) => {
  try {
    const response = await Client.lock(body);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const postUnlockEmployee = async (body) => {
  try {
    const response = await Client.unlock(body);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const postFireEmployee = async (body) => {
  try {
    const response = await Client.fire(body);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const putEmployee = async (id, employee) => {
  try {
    const response = await Client.employeePUT(id, employee);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteEmployee = async (id) => {
  try {
    const response = await Client.employeeDELETE(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};
