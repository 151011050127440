import React, { useRef, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  FontIcon,
  ContextualMenu,
  Panel,
  PanelType,
  PrimaryButton,
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
} from "office-ui-fabric-react";
import { toast } from "react-toastify";
import Form from "../Common/Form";
import {
  putEmployee,
  postLockEmployee,
  postFireEmployee,
  postUnlockEmployee,
  syncWithAD,
  // promoteToRestaurantAdmin,
  getUnitEmployeesLookup,
  assignEmployeeToMailbox,
  unassignEmployeeToMailbox
} from "../Services/EmployeeService";
import { useStateValue } from "../State/stateProvider";

// eslint-disable-next-line no-useless-escape
const emailTester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

// eslint-disable-next-line no-useless-escape
const phoneTester = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}[-\s\.]?([0-9]{3,6})?/;

const bonAmpetitCardTester = /^([\d]{16})?$/;

const capitalizeFirstLetterOfEveryWord = (inputString) => {
  if (!inputString) return inputString;

  const pattern = /(\p{L})(\p{L}*)/gu;
  const transform = (_, firstLetter, restOfWord) =>
      firstLetter.toUpperCase() + restOfWord.toLowerCase();

  return inputString.replace(pattern, transform);
};

const STYLE_MARGIN = { marginTop: 0, marginBottom: 24 };
const STYLE_MARGIN_TOP = { marginTop: 0 };

const UserAction = ({ item }) => {
  const [{ auth, management, allLabels: { labels } }, dispatch] = useStateValue();
  const { jobTitles, employeeTypes, unitsDictionary } = management;
  const { groups } = auth;
  const { unit, structure } = useParams();
  const linkRef = useRef(null);
  const [editItem, setEditItem] = useState({});
  const [showContextualMenu, setShowContextualMenu] = useState(false);
  const onShowContextualMenu = () => setShowContextualMenu(true);
  const onHideContextualMenu = () => setShowContextualMenu(false);
  const [unitLookupEmployees, setUnitLookupEmployees] = useState([]);

  const [isEditing, setIsEditing] = useState(false);

  const openEditPanel = () => setIsEditing(true);
  const dismissEditPanel = () => setIsEditing(false);

  const [dialog, setDialog] = useState(true);
  const [type, setType] = useState();
  const [contextItems, setContextItems] = useState([]);

  useEffect(() => {
    let mounted = true;
    const updateEmployees = async () => {
      if (editItem.unitId) {
        const unitEmployees = await getUnitEmployeesLookup(editItem.unitId);
        if (mounted) {
          if (unitEmployees.data) {
            setUnitLookupEmployees([
              { key: "", text: "" },
              ...unitEmployees.data.map((entry) => ({
                ...entry,
                key: entry.id,
                text: `${entry.firstname} ${entry.surname}`,
              })),
            ]);
          } else {
            setUnitLookupEmployees([]);
          }
        }
      } else {
        setUnitLookupEmployees([]);
      }
    };
    updateEmployees();

    return () => {
      mounted = false;
    };
  }, [editItem.unitId]);

  useEffect(() => {
    if (editItem.supervisorId && unitLookupEmployees.length) {
      const supervisor = unitLookupEmployees.find(
        (entry) => entry.id === editItem.supervisorId
      );
      if (!supervisor) {
        setEditItem((oldItem) => {
          return {
            ...oldItem,
            supervisorId: null,
          };
        });
      }
    }
  }, [editItem.supervisorId, unitLookupEmployees]);

  const openDialog = (type) => {
    setType(type);
    setDialog(false);
  };

  const closeDialog = () => {
    setDialog(true);
  };

  const handleSyncWithAD = useCallback(async () => {
    const response = await syncWithAD({ employeeId: item.id });
    if (response) {
      dispatch({
        type: "changeRefreshData",
      });
    }
  }, [dispatch, item.id]);

  // const handlePromoteToAdmin = useCallback(async () => {
  //   const response = await promoteToRestaurantAdmin({ employeeId: item.id });
  //   if (response) {
  //     dispatch({
  //       type: "changeRefreshData",
  //     });
  //   }
  // }, [dispatch, item.id]);

  const handleAssignEmployeeToMailbox = useCallback(async () => {
    const success = await assignEmployeeToMailbox(item.id);

    if (success) {
      dispatch({
        type: "changeRefreshData",
      });
    }
  }, [dispatch, item.id]);

  const handleUnassignEmployeeToMailbox = useCallback(async () => {
    const success = await unassignEmployeeToMailbox(item.id);

    if (success) {
      dispatch({
        type: "changeRefreshData",
      });
    }
  }, [dispatch, item.id]);

  useEffect(() => {
    const menuItems = [];

    if (!item.isInSyncWithAD && item.canBeForceSynced) {
      menuItems.push({
        key: "syncWithAD",
        text: "Sync With AD",
        onClick: handleSyncWithAD,
      });
    }

    if (item.status === "active" || item.status === "deactive") {
      // if (groups.includes(labels.get('global')) || groups.includes(labels.get('local')) || groups.includes(labels.get('regional'))) {
      //   menuItems.push({
      //     key: "syncWithAD",
      //     text: "Promote to Restaurant Admin",
      //     onClick: handlePromoteToAdmin,
      //   });
      // }

      menuItems.push({
        key: "edit",
        text: labels.get('edit'),
        onClick: openEditPanel,
      });
      if (!item.createMailbox && item.mailboxStatus?.toLowerCase() === "notcreated") {
        menuItems.push({
          key: "mailbox",
          text: labels.get('create_mailbox'),
          onClick: handleAssignEmployeeToMailbox,
          isDisabled: !item.canCreateMailbox
        });
      } else if (item.mailboxStatus) {
        menuItems.push({
          key: "mailbox",
          text: labels.get('remove_mailbox'),
          onClick: handleUnassignEmployeeToMailbox,
          isDisabled: !item.canRemoveMailbox
        });
      }
    }

    if (item.status === "active") {
      menuItems.push({
        key: "lock",
        text: labels.get('lock'),
        onClick: () => openDialog("Lock"),
      });
    }

    if (item.status === "deactive") {
      menuItems.push({
        key: "unlock",
        text: labels.get('unlock'),
        onClick: () => openDialog("Unlock"),
      });
      menuItems.push({
        key: "fire",
        text: labels.get('fire'),
        onClick: () => openDialog("Fire"),
      });
    }

    setContextItems(menuItems);
  }, [handleSyncWithAD, item.status, item.isInSyncWithAD, item.canBeForceSynced, groups, handleAssignEmployeeToMailbox, item.createMailbox, item.mailboxStatus]);

  const confirmDialog = () => {
    if (type === "Fire") {
      postFireEmployee({
        employees: [item.id],
      })
        .then(() => {
          setDialog(true);
          dispatch({
            type: "changeRefreshData",
          });
        })
        .catch((e) => console.log(e));
    } else if (type === "Lock") {
      postLockEmployee({
        employees: [item.id],
      })
        .then(() => {
          setDialog(true);
          dispatch({
            type: "changeRefreshData",
          });
        })
        .catch((e) => console.log(e));
    } else if (type === "Unlock") {
      postUnlockEmployee({
        employees: [item.id],
      })
        .then(() => {
          setDialog(true);
          dispatch({
            type: "changeRefreshData",
          });
        })
        .catch((e) => console.log(e));
    }
  };

  const confirmChanges = async () => {
    const error = Object.entries(editItem).find(([key, value]) => {
      if (key === "emailPrivate" || key === "emailWork") {
        if (value && !emailTester.test(value)) {
          return true;
        }
      }

      if (key === "phoneMobile" || key === "phonePrivate") {
        if (value && !phoneTester.test(value)) {
          return true;
        }
      }

      if(key === "bonAmpetitCard") {
        if(value && !bonAmpetitCardTester.test(value)) {
          return true;
        }
      }

      return false;
    });

    const requiredDataMissing =
      !editItem.id ||
      !editItem.firstname ||
      !editItem.surname ||
      !editItem.emailPrivate ||
      !(editItem.level >= 0);

    editItem.firstname = capitalizeFirstLetterOfEveryWord(editItem.firstname);
    editItem.surname = capitalizeFirstLetterOfEveryWord(editItem.surname);

    if (!error && !requiredDataMissing) {
      const response = await putEmployee(item.id, editItem);

      if (response?.data) {
        setEditItem(response.data);
        dispatch({
          type: "changeRefreshData",
        });
        dismissEditPanel();
      }
    } else {
      if (error) {
        toast.error(labels.get('correct_form_errors'));
      }
      if (requiredDataMissing) {
        toast.error(labels.get('fill_required_fields'));
      }
    }
  };

  const onRenderFooterContent = () => (
    <div>
      <PrimaryButton onClick={confirmChanges}>Save</PrimaryButton>
    </div>
  );

  const changeItem = (value, field) => {
    const newItem = { ...editItem, [field]: value };

    if (field === "unitId") {
      if (value?.id) {
        if (newItem.unitId !== value.id) {
          newItem.supervisorId = null;
          newItem.supervisor = null;
        }

        newItem.unitId = value.id;
        newItem.unit = value;
      } else {
        newItem.unitId = null;
        newItem.unit = null;
        newItem.supervisorId = null;
        newItem.supervisor = null;
      }
    } else if (field === "employeeTypeId") {
      if (value?.id) {
        newItem.employeeTypeId = value.id;
        newItem.employeeType = value;
      } else {
        newItem.employeeTypeId = null;
        newItem.employeeType = null;
      }
    } else if (field === "jobTitleId") {
      if (value?.id) {
        newItem.jobTitleId = value.id;
        newItem.jobTitle = value;
        newItem.level = jobTitles.find(j => j.id === value.id)?.defaultLevel;
      } else {
        newItem.jobTitleId = null;
        newItem.jobTitle = null;
        newItem.level = null;
      }
    } else if (field === "supervisorId") {
      if (value?.id) {
        newItem.supervisorId = value.id;
        newItem.supervisor = value;
      } else {
        newItem.supervisorId = null;
        newItem.supervisor = null;
      }
    }

    setEditItem(newItem);
  };

  const fields = [
    {
      type: "column",
      key: "1",
      separator: true,
      fields: [
        {
          type: "dropdown",
          label: labels.get('restaurant'),
          name: "unitId",
          options: unitsDictionary.filter(
            (unit) => unit.structureCode === item?.structureCode
          ),
        },
        {
          type: "text",
          label: labels.get('structure_code'),
          name: "structureCode",
          disabled: true,
        },
      ],
    },
    {
      type: "column",
      key: "2",
      fields: [
        {
          type: "text",
          label: labels.get('upn'),
          name: "principalId",
          disabled: true,
        },
        {
          type: "dropdown",
          label: labels.get('supervisor'),
          name: "supervisorId",
          options: unitLookupEmployees?.filter((entry) => entry.id !== item.id),
        },
        {
          type: "text",
          label: labels.get('firstname'),
          name: "firstname",
          required: true,
        },
        {
          type: "text",
          label: labels.get('surname'),
          name: "surname",
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('job_title'),
          name: "jobTitleId",
          options: jobTitles,
          required: true,
        },
      ],
    },
    {
      type: "columns",
      key: "3",
      fields: [
        // {
        //   type: 'dropdown',
        //   label: 'Status',
        //   name: 'status',
        //   options: [
        //     { key: 'active', text: 'Active' },
        //     { key: 'deactive', text: 'Deactive' },
        //     { key: 'fired', text: 'Fired' },
        //   ],
        // },
        {
          type: "number",
          label: labels.get('level'),
          name: "level",
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('employee_type'),
          name: "employeeTypeId",
          options: employeeTypes,
          required: true,
        },
      ],
    },
    {
      type: "column",
      key: "4",
      separator: true,
      fields: [
        {
          type: "text",
          label: labels.get('bon_ampetit_card'),
          name: "bonAmpetitCard",
          required: false,
        },
      ],
    },
    {
      type: "columns",
      key: "5",
      fields: [
        {
          type: "phone",
          label: labels.get('phone_mobile'),
          name: "phoneMobile",
        },
        {
          type: "phone",
          label: labels.get('phone_private'),
          name: "phonePrivate",
        },
      ],
    },
    {
      type: "columns",
      key: "6",
      fields: [
        {
          type: "email",
          label: labels.get('email_work'),
          name: "emailWork",
        },
        {
          type: "email",
          label: labels.get('email_private'),
          name: "emailPrivate",
          required: true,
        },
        {
          type: "date",
          label: labels.get('start_work_day'),
          name: "startWorkDate",
        },
        {
          type: "date",
          label: labels.get('end_work_day'),
          name: "endWorkDate",
        },
      ],
    },
  ];

  useEffect(() => {
    setEditItem({
      unitId: unit,
      structureCode: structure,
      employeeTypeId: item.employeeType.id,
      jobTitleId: item.jobTitle.id,
      ...item,
    });
  }, [isEditing, item, structure, unit]);

  let typeLabel = "";

  switch (type) {
    case "Lock":
      typeLabel = labels.get('lock');
      break;
    case "Unlock":
      typeLabel = labels.get('unlock');
      break;
    case "Fire":
      typeLabel = labels.get('fire');
      break;
  }

  return (
    <div className={`user-action ${showContextualMenu ? "active" : ""}`}>
      {contextItems.length ? (
        <button ref={linkRef} onClick={onShowContextualMenu}>
          <FontIcon iconName="MoreVertical" className="moreButton" />
        </button>
      ) : null}
      <ContextualMenu
        items={contextItems}
        hidden={!showContextualMenu}
        target={linkRef}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
      />
      <Dialog
        hidden={dialog}
        onDismiss={closeDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: `${typeLabel} ${labels.get('employee')}`,
        }}
      >
        <p style={type === "Fire" ? STYLE_MARGIN_TOP : STYLE_MARGIN}>
          {labels.get('are_you_sure_you_want_to')} {typeLabel} {item.firstname} {item.surname}?
        </p>
        <p style={{ fontWeight: 600, color: "red" }}>
          {type === "Fire" ? labels.get('this_operation_is_irreversible') : ""}
        </p>
        <DialogFooter>
          <PrimaryButton onClick={confirmDialog} text={typeLabel} />
          <DefaultButton onClick={closeDialog} text={labels.get('cancel')} />
        </DialogFooter>
      </Dialog>
      <Panel
        headerText={`Edit - ${item.firstname} ${item.surname}`}
        type={PanelType.custom}
        customWidth={"28rem"}
        isLightDismiss
        isOpen={isEditing}
        onDismiss={dismissEditPanel}
        closeButtonAriaLabel={labels.get('close')}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Form item={editItem} changeItem={changeItem} fields={fields} />
      </Panel>
    </div>
  );
};

export default UserAction;
